import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Profile from './components/Profile';
import GameTable from './components/GameTable';
import Shop from './components/Shop';
import ErrorBoundary from './components/ErrorBoundary';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/game" element={<GameTable />} />
            <Route path="/shop" element={<Shop />} />
          </Routes>
        </ErrorBoundary>
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import VideoChat from './VideoChat';
import BetTimer from './BetTimer';
import { sounds } from '../utils/sounds';
import './GameTable.css';

const socket = io('http://bc.testmandant.com:5000'); // Passen Sie die URL an Ihre Server-URL an

function GameTable() {
  const [gameState, setGameState] = useState({ players: [], communityCards: [] });

  useEffect(() => {
    socket.on('gameState', (state) => {
      setGameState(state);
      sounds.cardFlip.play();
    });

    // Anfrage zum Starten des Spiels senden
    socket.emit('startGame');

    return () => socket.off('gameState');
  }, []);

  const handleTimeUp = () => {
    alert('Time is up!');
  };

  return (
    <div className="game-table">
      <div className="community-cards">
        {gameState.communityCards.map((card, index) => (
          <div key={index} className="card">{card}</div>
        ))}
      </div>
      <div className="player-cards">
        {gameState.players.map((player, index) => (
          <div key={index} className="player">
            <div className="player-name">{player.name}</div>
            <div className="cards">
              {player.cards.map((card, cardIndex) => (
                <div key={cardIndex} className="card">{card}</div>
              ))}
            </div>
            <VideoChat playerId={player.id} />
            <BetTimer duration={60} onTimeUp={handleTimeUp} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default GameTable;

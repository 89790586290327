import React from 'react';
import './Shop.css';

function Shop() {
  return (
    <div className="shop">
      <h1>Welcome to the Shop</h1>
    </div>
  );
}

export default Shop;

import { Howl, Howler } from 'howler';

export const sounds = {
  cardFlip: new Howl({ src: ['/sounds/card_flip.mp3'] }),
  chipStack: new Howl({ src: ['/sounds/chip_stack.mp3'] }),
  betPlace: new Howl({ src: ['/sounds/bet_place.mp3'] }),
  win: new Howl({ src: ['/sounds/win_sound.mp3'] }),
  lose: new Howl({ src: ['/sounds/lose_sound.mp3'] }),
};

document.addEventListener('click', () => {
  Howler.ctx.resume().then(() => {
    console.log('AudioContext resumed');
  });
});

import React, { useEffect, useRef } from 'react';
import './VideoChat.css';

function VideoChat({ playerId }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          videoRef.current.srcObject = stream;
        })
        .catch(error => console.error('Error accessing webcam:', error));
    } else {
      console.error('getUserMedia is not supported in this browser.');
    }
  }, []);

  return (
    <div className="video-chat">
      <video ref={videoRef} autoPlay playsInline />
    </div>
  );
}

export default VideoChat;
